@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: Proxima Nova;
    font-weight: 400;
    src: url(./assets/fonts/Mark\ Simonson\ -\ Proxima\ Nova.otf) format("woff");
  }
  @font-face {
    font-family: Proxima Nova;
    font-weight: 500;
    src: url(./assets/fonts/Mark\ Simonson\ -\ Proxima\ Nova\ Semibold.otf) format("otf");
  }
  @font-face {
    font-family: Proxima Nova;
    font-weight: 600;
    src: url(./assets/fonts/Mark\ Simonson\ -\ Proxima\ Nova\ Bold.otf) format("woff");
  }
}
body{
  background-color: #1C1C1C;
}
